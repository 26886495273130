
import axios from 'axios';
export default {
    
    async createIncurrencesTakeOuts(params)  {
        return await axios.post(`incurrences_take_outs/create` , params)
    },
    async createIncurrencesTakeOutsList(params)  {
        return await axios.post(`incurrences_take_outs/create/list` , params)
    },
    async updateIncurrencesTakeOutsColumn(column , value , data)  {
        return await axios.put(`incurrences_take_outs/update_list?${column}=${value}` , data)
    },
    async deleteIncurrencesTakeOutsList(list)  {
        return await axios.delete(`incurrences_take_outs/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportIncurrencesTakeOuts(column , value)  {
        return await axios.get(`incurrences_take_outs/report?${column}=${value}`)
    },
    async getAllIncurrencesTakeOuts()  {
        return await axios.get(`incurrences_take_outs/all`)
    },
    async getOneIncurrencesTakeOuts(incurrences_take_out_id)  {
        return await axios.get(`incurrences_take_outs/all/${incurrences_take_out_id}`)
    },
    async getIncurrencesTakeOutsByColumn(column , value)  {
        return await axios.get(`incurrences_take_outs/filter?column=${column}&value=${value}`)
    },
    async deleteIncurrencesTakeOuts(incurrences_take_out_id)  {
        return await axios.delete(`incurrences_take_outs/delete/${incurrences_take_out_id}`)
    },
    async updateIncurrencesTakeOuts(incurrences_take_out_id , params)  {
        return await axios.put(`incurrences_take_outs/update/${incurrences_take_out_id}` , params)
    }
}